import(/* webpackMode: "eager" */ "/home/runner/work/irs-extension.online/irs-extension.online/node_modules/@atomicleads/ext-satellite-form/dist/index.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/irs-extension.online/irs-extension.online/node_modules/bootstrap/dist/css/bootstrap.min.css");
;
import(/* webpackMode: "eager", webpackExports: ["GoogleAnalytics"] */ "/home/runner/work/irs-extension.online/irs-extension.online/src/components/Analytics/GoogleAnalytics/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["GoogleTagManager"] */ "/home/runner/work/irs-extension.online/irs-extension.online/src/components/Analytics/GoogleTagManager/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/home/runner/work/irs-extension.online/irs-extension.online/src/providers/index.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/irs-extension.online/irs-extension.online/src/styles/fonts.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/irs-extension.online/irs-extension.online/src/styles/globals.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/irs-extension.online/irs-extension.online/src/styles/common.scss");
