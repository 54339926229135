'use client';

import { GTM } from '@atomicleads/ext-satellite-form';

const gtmMeasurementID = process.env.NEXT_PUBLIC_GTM_MEASUREMENT_ID;

export const GoogleTagManager = () => {
  if (!gtmMeasurementID) return null;

  return <GTM gtmMeasurementID={gtmMeasurementID} domain={'https://sgtm.irs-extension.online'} />;
};
